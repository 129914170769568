import Icono from "../../design/assets/img/wfi/icons/icon-audience.svg";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import * as AudienceRuleTypes from "../../models/audience-rule.model";
import useToastContext from "hooks/useToastContext.hook";
import { CONST } from "../../consts/consts";
import { getAudienceById, getAudienceStatistics} from "service/Audience.service";
import { useEffect, useState, useRef } from "react";
import NeoSpinner from "design/design_components/neo/overlay/NeoSpinner.base";
import DisableAudienceButton from "./components/DisableAudienceButton.component";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import AudienceAdTable from "./components/AudienceAdTable.component";
import AudienceContactCounter from "./components/AudienceContactCounter.component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import NeoTooltip_icon from "../../design/design_components/neo/overlay/NeoTooltip_icon.base";


/**
 * @type {AudienceRuleTypes.UpdateAudienceModel}
 */
const defaultAudience = null;
const defaultAudienceStatistics= null;


export default function DetailsAudience() {
    const [audience, setAudience] = useState(defaultAudience);
    const [statistics, setStatistics] = useState(defaultAudienceStatistics);
    const { id: audienceId } = useParams();
    const toast = useToastContext();
    const history = useHistory();
    const contactCounterRef = useRef(null);
    const [rules, setRules] = useState(audience?.RULES);

    const [singlerules, setSingleRules] = useState([]);
    const [multiplerules, setMultipleRules] = useState([]);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchAudience();
            
        }

        return () => {
            isMounted = false;
        }
    }, []);


    async function fetchAudience() {
        const response = await getAudienceById(Number(audienceId));
        if (!response.success) {
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );
        }
      
        setAudience(response.payload);
        setRules(response.payload.RULES);

        setSingleRules(response.payload.SINGLE_RULES_TOTEXT);
        setMultipleRules(response.payload.MULTIPLE_RULES_TOTEXT);
        
        /*console.log("Arreglo Original",response.payload.ALL_RULES_TOTEXT);
        console.log("Arreglo de singles",response.payload.SINGLE_RULES_TOTEXT);
        console.log("Arreglo de multiples",response.payload.MULTIPLE_RULES_TOTEXT);*/
        
    }

    useEffect(() => {
      const fetchStatistics = async () => {
          const response = await getAudienceStatistics(audienceId);
          if (!response.success) {
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );
         }
         if (response.payload && Array.isArray(response.payload) && response.payload.length > 0) {
              setStatistics(response.payload[0]);
          }
      };

      const fetchRules = async () => {
        const response = await getAudienceStatistics(audienceId);
        if (!response.success) {
          return toast.setMessage(
              CONST.SEVERITY.ERROR,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
          );
       }
       if (response.payload && Array.isArray(response.payload) && response.payload.length > 0) {
            setStatistics(response.payload[0]);
        }
    };

      fetchStatistics();
   
  }, [audienceId]);

    const handleEdit = (rowData) => {
        history.push(`/audiencias/editar-audiencia/${audienceId}`);
    };

    if (audience === null) {
        return <NeoSpinner />;
    }


    return (
        <>
          <div className="p-d-flex gap-7 mb-16">
                <div>
                    <NeoTitleSecond title="Detalles de audiencia" icon={Icono} goBack={true} extra="mb-0" />
                </div>

                <div className="p-d-flex p-ai-center">
                    <span className={"badge badge-regular fs-14 pl-8 pr-8 pt-4 pb-4 " + (audience.IS_ENABLED ? 'badge-success' : 'badge-gray')}>
                        {audience.IS_ENABLED ? 'Habilitado' : 'Deshabilitado'}
                    </span>
                </div>
                
                <div className="p-d-flex gap-10 ml-auto p-ai-center">
                    <DisableAudienceButton 
                        audienceId={Number(audience.ID)} 
                        isEnabled={audience.IS_ENABLED} 
                        onStatusUpdated={(isEnabled) => setAudience({ ...audience, IS_ENABLED: isEnabled })}
                    />
                     <NeoButtonMain
                        label="Editar audiencia"
                        icon="pi pi-pencil"
                        onClick={handleEdit}
                        />
                </div>
             
            </div>

            <div className="p-d-flex p-flex-column gap-16 w-full">

                <NeoCard custom="custom-padding-card m-0 p-d-flex p-flex-column">
                  <div className="p-col p-d-flex p-flex-column gap-15 pb-0">
                    <h2 className="fs-22 bold text-primary p-m-0 p-p-0">Información general</h2>
                    <div className="p-d-flex gap-16 pb-4">
                
                      <div className="p-d-flex" style={{ flex: '0 0 40%', flexDirection: 'column' }}>
                        <div style={{ fontSize: '14px' }}>Nombre</div>
                        <div style={{ marginTop: '6px' }} className="fs-16 bold">{audience.NAME}</div>
                      </div>
                      <div className="p-d-flex" style={{ flex: '0 0 40%', flexDirection: 'column' }}>
                        <div style={{ fontSize: '14px' }} >Descripción</div>
                        <div style={{ marginTop: '6px' }} className="fs-16 bold"> {audience.DESCRIPTION ? audience.DESCRIPTION : "-"}</div>
                      </div>
                      <div className="p-d-flex" style={{ flex: '0 0 20%', flexDirection: 'column' }}>
                        <AudienceContactCounter rules={rules} ref={contactCounterRef} />
                      </div>
                    </div>
                  </div>
                </NeoCard>

                
    
      <div className="p-d-flex gap-16" style={{ flex: '1 1 70%', margin: '0' }}>

          <NeoCard custom="custom-padding-card m-0 p-d-flex p-flex-column">
              <div className="p-col p-d-flex p-flex-column gap-15 pb-0">
                <h2 className="fs-22 bold text-primary p-m-0 p-p-0">Resumen de la audiencia
                <NeoTooltip_icon position="bottom" extra="inline-tooltip" text="Es el número de contactos que cumplen con los criterios de la audiencia, el cual puede variar según su comportamiento al momento del anuncio." />
                </h2>
                <div className="p-d-flex gap-16 pb-4">
                  <div className="p-d-flex p-flex-column" style={{ flex: '1', paddingRight: '8px' }}>
                    <div className="mb-4 fs-16 bold" >Debe cumplir exactamente con:</div>
                      {singlerules.map((item, index) => (
                        <div key={index} className="custom-list-item mb-4 fs-14">
                          {item}
                      </div>
                      ))}
                  </div>

                
                  <div style={{ width: '1px', backgroundColor: '#ccc', margin: '0 16px' }} />


                  <div className="p-d-flex p-flex-column" style={{ flex: '1', paddingLeft: '8px' }}>
                    <div className="mb-4 fs-16 bold">Debe cumplir al menos una condición:</div>
                      {multiplerules.map((item, index) => (
                        <div key={index} className="custom-list-item mb-4 fs-14">
                          {item}
                        </div>
                      ))}
                  </div>
 
                </div>
              </div>
          </NeoCard>

          {statistics &&
            <NeoCard custom="custom-padding-card m-0 p-d-flex p-flex-column" style={{ flex: '0 0 30%' }}>
              <div className="p-col p-d-flex p-flex-column gap-15 pb-0">
                <h2 className="fs-22 bold text-primary p-m-0 p-p-0">Uso</h2>
                
                <div className="p-d-flex gap-16 pb-4">
                  <div className="p-d-flex p-flex-column" style={{ flex: '1', paddingRight: '8px' }}>
                    <div className="mb-4" style={{ fontSize: '14px' }}>Campañas</div>
                    <div className="mb-4 fs-18 bold">{statistics.TOTAL_CAMPAIGNS}</div>
                  </div>

            
                  <div className="p-d-flex p-flex-column" style={{ flex: '1', paddingRight: '8px' }}>
                    <div className="mb-4" style={{ fontSize: '14px' }}>Sucursales</div>
                    <div className="mb-4 fs-18 bold">{statistics.TOTAL_STORES}</div>
                  </div>

            
                  <div className="p-d-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="mb-4" style={{ fontSize: '14px' }}>Anuncios</div>
                    <div className="mb-4 fs-18 bold">{statistics.TOTAL_ADS}</div>
                  </div>
                    
                </div>
              </div>
            </NeoCard>
          }

   
    </div>

    


    <NeoCard custom="custom-padding-card m-0 p-d-flex p-flex-column">
        <div className="p-col p-d-flex p-flex-column gap-15 pb-0">
          <h2 className="fs-22 bold text-primary p-m-0 p-p-0">Anuncios activos</h2>
          <div className="p-d-flex gap-16 pb-4">
            <AudienceAdTable audienceId={audience.ID}></AudienceAdTable>
          </div>
        </div>
    </NeoCard>

  

  </div>

  </>
 );
}