import React, { useRef, useState, useEffect } from "react";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base.js";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base.js";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base.js";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base.js";
import {getAdDataByAudience} from "../../../service/Media.service.js";
import {filterRangeDate} from "../../../utils/date.utils.js";
// Componentes nuevos
import { Galleria } from "primereact/galleria";



export default function AudienceAdTable({audienceId}) {
  
  const mediaTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [audienceCollection, setAudienceCollection] = useState([]); 
  const galleria = useRef(null);
  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  
  const [selectedRow, setSelectedRow] = useState(null); 
  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, setFilters] = useState({});
  const ROWS_COUNT = 10;
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
  });

  const [rowData, setRowData] = useState(null); 

   // Obtener los datos
   useEffect(() => {
    let mount = true;

    if (mount) {
      gettingAdDataByAudience();
    }
    return () => {
      mount = false;
    };
  }, []);
  

  // Obtener la información de los anuncios por audiencia
  const gettingAdDataByAudience = async () => {
    setLoading(true);
    const rawMediaAudience = await getAdDataByAudience(audienceId);
    setAudienceCollection(rawMediaAudience.result);
    setLoading(false);
  };

 
  // Manejo del clic para mostrar la galería de imágenes
  const handleImageClick = (data) => {
    const galleryImages = [];

    if (data.URL) {
      galleryImages.push({
        source: data.URL, // Imagen de Escritorio
        thumbnail: data.URL,
        title: "Archivo para computadora de escritorio",
      });
    }
    if (data.URL_ANDROID) {
      galleryImages.push({
        source: data.URL_ANDROID, // Imagen Android
        thumbnail: data.URL_ANDROID,
        title: data.URL_IOS ? " Archivo para Android" : "Archivo para Android / iOS",
      });
    }
    if (data.URL_IOS) {
      galleryImages.push({
        source: data.URL_IOS, // Imagen iOS
        thumbnail: data.URL_IOS,
        title: data.URL_ANDROID ? "Archivo para iOS" : "Archivo para Android / iOS",
      });
    }
    setImages(galleryImages);
    setActiveIndex(0);
  };

 
  // Efecto para abrir la galería de imágenes
  useEffect(() => {
    if (
      images.length > 0 &&
      galleria.current &&
      typeof galleria.current.show === "function"
    ) {
      setTimeout(() => {
        galleria.current.show();
      }, 100);
    }
  }, [images, galleria]);

  // Perder el foco del control de fecha al limpiar para que vuelva a mostrar el calendario
  const handleClearButtonClick = (e) => {
    setTimeout(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, 0);
  };

   useEffect(() => {
    setIsFiltered(Object.keys(filters).length > 0);
  }, [filters]);


  // Filtros de la tabla 

  const nameFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"AD_NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
        className="custom-place"

      />
    );
  };

  const campaignFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"CAMPAIGN_NAME"}
        placeholder={"Buscar por campaña"}
        filterMatch={"contains"}
        className="custom-place"

      />
    );
  };

  const storesFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"STORES_ASSIGNED"}
        placeholder={"Buscar por sucursal"}
        filterMatch={"contains"}
        className="custom-place"

      />
    );
  };

  const periodFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={mediaTableRef}
        field={"PERIOD"}
        placeholder={"Buscar por periodo"}
        matchFilter="custom"
        readOnlyInput
        onClearButtonClick={handleClearButtonClick}
      />
    );
  };

  

  // Template para la vista previa de la imagen
  const previewBodyTemplate = (data) => {
    if (data.URL) {
      return (
        <div
          className="image-container"
          onClick={(e) => {
            e.stopPropagation(); // Detener propagación para que no se dispare onRowSelect
            handleImageClick(data);
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={data.URL}
            alt={data.NAME}
            style={{
              cursor: "pointer",
              height: "60px",
              width: "60px",
              objectFit: "cover",
              borderRadius: "3px",
              transition: "all 0.3s ease-in-out",
            }}
            onClick={() => handleImageClick(data)} // Solo se activa si hay URL
          />
          <div className="image-overlay">
            <i className="pi pi-eye eye-icon"></i>
          </div>
        </div>
      );
    } else {
      // Si no hay URL, simplemente devuelve vacío
      return <span></span>;
    }
  };

 
  // Template para los items de la galería de ocuparse
  const itemTemplate = (item) => {
    return (
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img src={item.source} alt={item.title} style={{ width: "100%" }} />
        <div
          style={{
           
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            marginTop: "10px", 
            display: "inline-block",
          }}
        >
          {item.title}
        </div>
      </div>
    );
  };


 
  // Template para las miniaturas de ocuparse
  const thumbnailTemplate = (item) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{ display: "block" }} />
    );
  };

  
  // Acción para el renglón seleccionado en todas las columnas a excepción de las acciones o las imagenes
  const onSelectRow = (data) => {
    
  };

  const rowClassName = (rowData) => {
    return rowData.ID_MEDIA == selectedRow ? "selected-row" : ""; // Aplica clase si es la fila seleccionada
  };

  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
  };

const emptyContent = () => {
  if (isFiltered) {
      return <span>No hay ningún anuncio.</span>;
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <p style={{ fontSize:'20px', fontWeight: 'bold', margin: '0' }}>Sin anuncios</p>
    </div>
  );
};


const onFilter = (event) => {
  let _lazyParams = { ...lazyParams, ...event };
  _lazyParams["first"] = 0;
  setLazyParams(_lazyParams);
  setIsFiltered(Object.keys(event.filters).length > 0);
};

 return (
    <>
      <div className="card flex justify-content-center">
        {images.length > 0 && (
          <Galleria
            ref={galleria}
            value={images}
            numVisible={3}
            style={{ maxWidth: "850px" }}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen
            circular
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
            
          />
        )}
      </div>
      <div className="custom-table-page">
          <NeoTable
            emptyMessage={emptyContent()}
            ref={mediaTableRef}
            value={audienceCollection}
            loading={loading}
            removableSort
            dataKey="ID_MEDIA"
            paginator
            rows={5}
            selectionMode={"single"}
            rowClassName={rowClassName} 
            onFilter={onFilter}
            filters={lazyParams.filters}
          >
            <NeoTableColumn
              field="PREVIEW"
              header="Preview"
              body={(e, index) => previewBodyTemplate(e, index)}
              style={{ width: "10%" }}
            />

            <NeoTableColumn
              field="AD_NAME"
              header="Nombre"
              placeholder="Nombre de anuncio"
              sortable
              filter
              filterElement={nameFilterElement()}
              style={{ width: "15%", textAlign: "left" }}
            />

            <NeoTableColumn
              field="CAMPAIGN_NAME"
              header="Campaña"
              placeholder="Nombre de campaña"
              sortable
              filter
              filterElement={campaignFilterElement()}
              style={{ width: "15%", textAlign: "left" }}
            />

            
            <NeoTableColumn
              field="STORES_ASSIGNED"
              header="Sucursales"
              placeholder="Sucursales"
              sortable
              filter
              filterElement={storesFilterElement()}
              style={{ width: "15%", textAlign: "left" }}
            />
        
            <NeoTableColumn
              field="PERIOD"
              header="Periodo de vigencia"
              sortable
              filter
              filterElement={periodFilterElement()}
              filterFunction={filterRangeDate}
              filterMatchMode="custom"
              body={(rowData) => rowData.PERIOD || "N/A"}
              className="centered-column"
              style={{ width: "20%" }}
            />
            
        </NeoTable>
      </div>
    </>
  );
}
