import { auth } from "../config/firebase.config";
import { CONST } from "../consts/consts";
import backendRequestUtil from "../utils/backendRequest.util";

const getCampaignInfoList = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(`${CONST.API_CAMPAIGN_ROUTE}/getCampaign`, requestOptions);
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};


const getDefaultCampaign = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(
      `${CONST.API_CAMPAIGN_ROUTE}/getDefaultCampaign`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const postNewCampaign = async (body) => {

  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_CAMPAIGN_ROUTE}/postnewCampaign`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getCampaignDataInfo = async (campaignId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({ campaignId: campaignId }),
  };

  try {
    result = await fetch(
      `${CONST.API_CAMPAIGN_ROUTE}/getCampaignData`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const updateCampaignStatus = async (campaignId, status) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({
      campaignId: campaignId,
      status: status,
    }),
  };

  try {
    result = await fetch(
      `${CONST.API_CAMPAIGN_ROUTE}/updateCampaignStatus`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getCampaignStore = async (campaignId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({ campaignId: campaignId}),
  };

  try {
    result = await fetch(
      `${CONST.API_CAMPAIGN_ROUTE}/getCampaignStore`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};


const getStatsCampaignByStore = async (campaignId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({
      campaignId: campaignId,
    }),
  };

  try {
    result = await fetch(`${CONST.API_CAMPAIGN_ROUTE}/getStatsCampaignByStore`, requestOptions);
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    console.log("error",error);
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};


  const getLookerDataInfo = async (campaignId) => {
    let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
    let result;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.currentUser.uid,
      },
      body: JSON.stringify({ campaignId: campaignId }),
    };
  
    try {
      result = await fetch(
        `${CONST.API_CAMPAIGN_ROUTE}/getLookerDataInfo`,
        requestOptions
      );
      if (!result.ok) {
        let errorResult = await result.json();
        response.message = errorResult.details;
        throw new Error();
      }
    } catch (error) {
      return response;
    }
  
    const resultJson = await result.json();
    response.result = resultJson.result;
    response.success = true;
    response.message = CONST.MESSAGES.SUCCESS;
    return response;
  };


  const getStatsCampaignByAudience = async (campaignId) => {
    let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
    let result;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.currentUser.uid,
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    };
  
    try {
      result = await fetch(`${CONST.API_CAMPAIGN_ROUTE}/getStatsCampaignByAudience`, requestOptions);
      if (!result.ok) {
        let errorResult = await result.json();
        response.message = errorResult.details;
        throw new Error();
      }
    } catch (error) {
      console.log("error",error);
      return response;
    }
  
    const resultJson = await result.json();
    response.result = resultJson.result;
    response.success = true;
    response.message = CONST.MESSAGES.SUCCESS;
    return response;
  };

export {
  getCampaignInfoList,
  getDefaultCampaign,
  postNewCampaign,
  getCampaignDataInfo,
  updateCampaignStatus,
  getCampaignStore,
  getStatsCampaignByStore,
  getLookerDataInfo,
  getStatsCampaignByAudience
};

