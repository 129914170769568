import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import {
  getMediaInfoList,
} from "../../../service/Media.service";
import { filterRangeNumber } from "../../../utils/commons.util";
import { filterRangeDate } from "../../../utils/date.utils";
import * as MEDIA_CONSTS from "../../../consts/media.consts.js";
import { Menu } from "primereact/menu";
import { DateTime } from "luxon";
import NeoTooltip_icon from "design/design_components/neo/overlay/NeoTooltip_icon.base";
import useToastContext from "../../../hooks/useToastContext.hook";

export default function MediaTable() {
  const mediaTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [mediaCollection, setMediaCollection] = useState([]); // Inicializado arreglo de datos de medios
  const history = useHistory();

  // Obtener la información de los medios
  const gettingMediaData = async () => {
    setLoading(true);
    const rawMedia = await getMediaInfoList();
 
    setMediaCollection(rawMedia.result);
    setLoading(false);
  };

  useEffect(() => {
    let mount = true;

    if (mount) {
      gettingMediaData();
    }
    
    return () => {
      mount = false;
    };
  }, []);

  // Perder el foco del control de fecha al limpiar para que vuelva a mostrar el calendario
  const handleClearButtonClick = (e) => {
    setTimeout(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, 0);
  };

  const nameFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"CONSECUTIVE_NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
        className="custom-place"
      />
    );
  };

  const campaignFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"CAMPAIGN_ID"}
        placeholder={"Buscar por campaña"}
        filterMatch={"contains"}
        className="custom-place"
      />
    );
  }

  const periodFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={mediaTableRef}
        field={"PERIOD"}
        placeholder={"Buscar por fecha"}
        matchFilter="custom"
        readOnlyInput
        onClearButtonClick={handleClearButtonClick}
      />
    );
  };

  const renderedFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"RENDERED"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const clicksFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CLICKS"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const ctrFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CTR"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const statusFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={mediaTableRef}
        options={Object.values(MEDIA_CONSTS.STATUS).map(s => ({ label: s.name, value: s.name }))}
        field={"STATUS"}
        placeholder="Todos"
        selectedItemsLabel="{0} estados de anuncio"
        display="chip"
        extra="custom-multi-select-filter p-0"
      />
    );
  };

  const nameBodyTemplate = (rowData) => {
    const redirectToDetail = () => {
      history.push(`/administracion-media/estadisticas/${rowData.ID}`);
    };

    return (
      <div className="fs-16 text-success bold text-underline underline-0.5 underline-offset-5 cursor-pointer" onClick={redirectToDetail}>
        { rowData.CONSECUTIVE_NAME }
      </div>
    )
  }

  const campaignBodyTemplate = (rowData) => {
    const idContent = `ID - ${rowData.CAMPAIGN_ID}`;
    const noIdContent = `N/A`;
    const underlineClass = 'text-underline underline-0.5 underline-offset-5'
    const defaultBadge = <span className="badge badge-regular badge-info fs-14 ml-10 pt-4 pb-4 pl-8 pr-8"><span className="mr-5">Default</span> <NeoTooltip_icon text="Campaña default: Los anuncios de esta campaña se mostrarán cuando no haya otros disponibles. No tiene vigencia ni sucursales." extra="inline-tooltip text-white" position="bottom"/> </span>
    const redirectToDetail = () => {
      history.push(`/campanias/view/${rowData.CAMPAIGN_ID}/${rowData.CAMPAIGN_CONSECUTIVE}`);
    }
    return (
      <div className={"fs-16 text-success bold " + (rowData.CAMPAIGN_ID ? (underlineClass + " cursor-pointer") : "")} onClick={redirectToDetail}>
        {rowData.CAMPAIGN_ID ? idContent : noIdContent}
        {rowData.CAMPAIGN_IS_DEFAULT ? defaultBadge : null}
      </div>
    )
  }
  
  const ctrBodyTemplate = (data) => {
    const ctr = Number(data.CTR) ? Number(data.CTR).toFixed(2) : 0;
    const adIsVideo = data.FILE_TYPE_ID === MEDIA_CONSTS.TYPE.VIDEO.ID;
    return (
      <>
        <span className="p-column-title">Clics</span>
        {adIsVideo ? "-" : `${ctr}%`}
      </>
    );
  };

  const statusBodyTemplate = (data) => {
    const status = data.STATUS ?? "Activo";

    const statusClass = {
      'Activo': 'dot-success',
      'Pausado': 'dot-warning',
      'Finalizado': 'dot-gray',
      'Programado': 'dot-amber',
      'Incompleto': 'dot-pink',
    }
    
    return (
      <div className="p-d-flex gap-10 p-ai-center">
        <span className={`dot ${statusClass[status]}`}></span>
        <span>{status}</span>
      </div>
    )
  }

  // Construcción del menú de acciones de cada registro
  const ActionsColumn = ({ rowData, index }) => {
    const menu = useRef(null);
    const canBeEdited = rowData.ID && rowData.CAMPAIGN_ID && rowData.CAMPAIGN_CONSECUTIVE;

    const handleEdit = (rowData) => {
      history.push(
        `/administracion-media/modificacion/${rowData.ID}/${rowData.CAMPAIGN_ID}/${rowData.CAMPAIGN_CONSECUTIVE}`
      );
    };

    const items = [
      {
        label: "Editar anuncio",
        command: () => handleEdit(rowData),
        disabled: !canBeEdited,
      },
    ];

    return (
      <div
        className="text-black cursor-pointer p-d-flex p-jc-center p-ai-center hover-text-green"
        onClick={(event) => {
          event.stopPropagation(); // Esto evita que se dispare onRowSelect
          menu.current.toggle(event);
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
        <Menu model={items} popup ref={menu} id={`menu_${index}`} />
      </div>
    );
  };

  /**
   * @param {{ field: string, order: 1|-1 }} event
   */
  const handleSortPeriod = (event) => {
    if (! Array.isArray(mediaCollection)) return [];

    return mediaCollection.sort((a, b) => {
      if (! a.PERIOD || ! b.PERIOD) return 0;
      const [aStart, aEnd] = a.PERIOD.split(' - ').map(e => DateTime.fromFormat(e, 'dd/MM/yyyy').toJSDate());
      const [bStart, bEnd] = b.PERIOD.split(' - ').map(e => DateTime.fromFormat(e, 'dd/MM/yyyy').toJSDate());

      if (aStart < bStart) return -event.order;
      if (aStart > bStart) return event.order;
      if (aEnd < bEnd) return -event.order;
      if (aEnd > bEnd) return event.order;

      return 0;
    });
  }

  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
};

  return (
    <>
       <NeoTable
        emptyMessage="No hay ningún anuncio"
        ref={mediaTableRef}
        value={mediaCollection}
        loading={loading}
        removableSort
        dataKey="ID"
        paginator
        rows={8}
        extra="custom-ad-table"
      >
        <NeoTableColumn
          field="CONSECUTIVE_NAME"
          header="ID/Nombre del anuncio"
          placeholder="Nombre del anuncio"
          sortable
          sortField="CONSECUTIVE"
          filter
          body={nameBodyTemplate}
          filterElement={nameFilterElement()}
          bodyClassName="hover-bg-green"
          style={{ textAlign: "left", width: "223px" }}
        />
        <NeoTableColumn
          field="PERIOD"
          header="Periodo"
          sortable
          filter
          filterElement={periodFilterElement()}
          filterFunction={filterRangeDate}
          filterMatchMode="custom"
          sortFunction={handleSortPeriod}
          body={(rowData) => rowData.PERIOD || "N/A"}
          className="centered-column"
          style={{ width: '220px' }}
        />
        <NeoTableColumn
          field="CAMPAIGN_ID"
          header="ID de la campaña"
          placeholder="ID de la campaña"
          sortable
          filter
          body={campaignBodyTemplate}
          filterElement={campaignFilterElement()}
          bodyClassName="hover-bg-green"
          style={{ textAlign: 'left', width: '210px' }}
        />
        <NeoTableColumn
          field="RENDERED"
          header="Impresiones"
          sortable
          filter
          filterElement={renderedFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
        />
        <NeoTableColumn
          field="CLICKS"
          header="Clics"
          sortable
          filter
          filterElement={clicksFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
        />
        <NeoTableColumn
          field="CTR"
          header="CTR"
          sortable
          filter
          filterElement={ctrFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
          body={(e) => ctrBodyTemplate(e)}
        />
        <NeoTableColumn
          field="STATUS"
          header="Estatus"
          sortable
          filter
          filterElement={statusFilterElement()}
          body={(e) => statusBodyTemplate(e)}
        />
        <NeoTableColumn
          field="actions"
          header=""
          body={(rowData, index) => (
            <ActionsColumn rowData={rowData} index={index} />
          )}
          style={{ width: "3%", padding: "0px" }}
        />
      </NeoTable>
    </>
  );
}
