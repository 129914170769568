import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import useToastContext from "hooks/useToastContext.hook";
import { useState } from "react";
import { getSplashVisitSimulationUrl } from "service/Store.service";
import { CONST } from "consts/consts";

/**
 * @param {{
 *  disabled: boolean,
 *  storeId: number
 * }} param0
 * @returns 
 */
export default function SimulateSplashVisitButton({ storeId, disabled }) {
    const [isLoading, setLoading] = useState(false);
    const toast = useToastContext();

    async function handleSimulateSplashVisit() {
        setLoading(true);
        
        try {
            const { success, payload: url } = await getSplashVisitSimulationUrl(storeId);

            if (!success || !url) {
                return alertError();
            }

            window.open(url, "_blank");
        } catch (error) {
            console.log(error);
            alertError();
        } finally {
            setLoading(false);
        }
    }

    function alertError() {
        toast.setMessage(
            CONST.SEVERITY.ERROR,
            CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
            CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
        );
    }

    return (
        <NeoButtonMain
            onClick={handleSimulateSplashVisit}
            alternative="outlined"
            disabled={isLoading || disabled}
            extra="action-button"
        >
            Simular visita de splash
            {isLoading && <i className="pi pi-spin pi-spinner ml-5"></i>}
        </NeoButtonMain>
    )
}