import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import { useContext } from "react";
import { groupBy } from "utils/commons.util";
import { ContactRulesContext } from "views/audiences/contexts/ContactRules.context";

export default function RuleCriteriaSelect(props) {
    const { ruleCriteria }  = useContext(ContactRulesContext);

    const groupedRuleCriteria = groupBy(ruleCriteria, criteria => criteria.CATEGORY);

    const options = Object.entries(groupedRuleCriteria).map(([category, criteria]) => ({
        label: category,
        code: category,
        items: criteria.map(criterion => ({
            label: criterion.NAME,
            value: criterion.ID,
        }))
    }));

    return (
        <NeoDropdown 
            placeholder="Selecciona un criterio"
            emptyMessage="No hay valores disponibles"
            options={options} 
            optionLabel="label" 
            optionGroupLabel="label" 
            optionGroupChildren="items"
            {...props}
        />
    );
}