import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Contacts from "../views/contacts/Contacts.view";
import Visits from "../views/visits/Visits.view";
import Home from "../views/home/Home.view";
import Principal from "../views/home/Principal.view";
import Store from "../views/stores/Store.view";
import Routes from "../routers/Routes.router.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContextProvider } from "../contexts/ToastContext.context";
import StoreDetails from "../views/stores/StoreDetails.view";
import StoreHeatmap from "../views/stores/StoreHeatmap.view";
import ContactDetails from "../views/contacts/ContactDetails.view";
import VisitDetails from "../views/visits/VisitDetails.view";
import SplashPages from "../views/splashPages/SplashPages.view";
import SplashPageDetails from "../views/splashPages/SplashPageDetails.view";
import ShippingConfiguration from "../views/deals/ShippingConfiguration.view";
import Settings from "../views/settings/Settings.view";
import { auth } from "../config/firebase.config";
import { logout } from "../contexts/useUserAuth.context";
import Shipping from "../views/deals/Shipping.view";
import PrivateRoute from "./components/PrivateRoute.component";
import { getUserStatus } from "../service/User.service";
import MailingList from "../views/mailing/MailingList.view";
import { actualUrl } from "../utils/sso.util";
import { CONST } from "../consts/consts";
import MediaList from "../views/media/MediaList.view";
import CampaniaList from "../views/campanias/CampaniaList.view";
import CampaniaDetails from "../views/campanias/CampaignDetails.view";
import MediaAdmin from "../views/media/components/MediaAdmin.component";
import MediaStatistics from "../views/media/MediaStatistics.view";
import AdDetails from "../views/media/AdDetails.view";
import MediaStatisticsStore from "../views/media/MediaStatisticsStore.view";
import APConnections from "../views/ap/Connections.view";
import CreateMedia from "../views/media/components/CreateMedia.component";
import CreateAudience from "views/audiences/CreateAudience.view";
import EditAudience from "views/audiences/EditAudience.view";
import AudienceList from "views/audiences/AudienceList.view";
import useNextTick from "hooks/useNextTick";
import CreateAdCampaign from "../views/media/components/CreateAdCampaign.component";
import UpdateAdCampaign from "../views/media/components/UpdateAdCampaign.component";
import CreateCampaign from "../views/campanias/components/CreateCampaign.component";
import UpdateCampaign from "../views/campanias/components/UpdateCampaign.component";
import DetailsAudience from "views/audiences/DetailsAudience.view";

export default function ClientRoutes() {
  const history = useHistory();
  const { nextTick } = useNextTick();
  const [deals, setDeals] = useState(null);
  const [menu, setMenu] = useState([]);
  const [headerShow, setHeaderShow] = useState(true);

  const handleSignOut = async () => {
    await logout();

    if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
      history.push("/landingPage");
    } else if (process.env.REACT_APP_ENVIRONMENT === "TEST") {
      const urlLogout = new URL(CONST.TEST_CONSTS.URL_LOGOUT_TEST);
      urlLogout.searchParams.set("continue", actualUrl("/logintest"));
      window.location.replace(urlLogout);
    } else if (process.env.REACT_APP_ENVIRONMENT === "STAGING") {
      const urlLogout = new URL(CONST.STAGING_CONSTS.URL_LOGOUT_STAGING);
      urlLogout.searchParams.set("continue", actualUrl("/logintest"));
      window.location.replace(urlLogout);
    } else if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
      const urlLogout = new URL(CONST.PROD_CONSTS.URL_LOGOUT_PROD);
      urlLogout.searchParams.set("continue", actualUrl("/logintest"));
      window.location.replace(urlLogout);
    } else {
      history.push("/");
    }
  };
  useEffect(() => {
    (async () => {
      const userStatus = await getUserStatus();
      setDeals(userStatus.result);
    })();
  }, []);

  useEffect(() => {
    const menu = [];

    if (deals) {
      if (deals.apsConfigured) {
        menu.push({
          label: "Analíticos",
          redirect: Routes.statistics.home,
          command: () => {
            history.push({
              pathname: Routes.statistics.home,
            });
          },
        });
        menu.push({
          label: "Contactos",
          redirect: Routes.contacts.home,
          command: () => {
            history.push({
              pathname: Routes.contacts.home,
            });
          },
        });
        menu.push({
          label: "Visitas",
          redirect: Routes.visits.home,
          command: () => {
            history.push({
              pathname: Routes.visits.home,
            });
          },
        });
        menu.push({
          label: "Sucursales",
          redirect: Routes.stores.home,
          command: () => {
            history.push({
              pathname: Routes.stores.home,
            });
          },
        });
        menu.push({
          label: "Splash pages",
          redirect: Routes.splashPages.home,
          command: () => {
            history.push({
              pathname: Routes.splashPages.home,
            });
          },
        });
        menu.push({
          label: "Notificaciones",
          redirect: Routes.mailing.home,
          command: () => {
            history.push({
              pathname: Routes.mailing.home,
            });
          },
        });

        switch (auth.currentUser?.email) {
          case "pizzahut@neomkt.com":
          case "tiendas3b@neomkt.com":
          case "liverpool@neored.info":
          case "liverpool-total@neored.info":
          case "liverpoolhola@neored.info":
            menu.push({
              label: "Campañas",
              redirect: Routes.campanias.home,
              command: () => {
                history.push({
                  pathname: Routes.campanias.home,
                });
                
                nextTick(() => document.body.click());
              },
              items: [
                {
                  label: "Anuncios",
                  redirect: Routes.media.home,
                  command: () => {
                    history.push({
                      pathname: Routes.media.home,
                    });
                  },  
                },
                {
                  label: "Audiencias",
                  redirect: Routes.audiences.home,
                  command: () => {
                    history.push({
                      pathname: Routes.audiences.home,
                    });
                  },  
                },
              ]
            });
            break;
          default:
            break;
        }

        menu.push({
          label: "Configuración",
          redirect: Routes.settings.home,
          command: () => {
            history.push({
              pathname: Routes.settings.home,
            });
          },
        });
      }

      menu.push({
        label: auth.currentUser.email,
        className: "signout",
        command: async () => handleSignOut(),
      });
    }

    setMenu(menu);
  }, [deals]);

  useEffect(() => {
    if (history.location.pathname === Routes.ap.connections) {
      setHeaderShow(false);
    } else {
      setHeaderShow(true);
    }
  }, [history?.location?.pathname]);

  return (
    <Principal menu={menu} headerShow={headerShow}>
      <ToastContextProvider>
        <Switch>
          <PrivateRoute path={"/estadisticas"} component={Home} />
          <PrivateRoute exact path={Routes.visits.home} component={Visits} />
          <PrivateRoute
            exact
            path={Routes.visits.details}
            component={VisitDetails}
          />
          <PrivateRoute
            exact
            path={Routes.contacts.home}
            component={Contacts}
          />
          <PrivateRoute
            exact
            path={Routes.contacts.details}
            component={ContactDetails}
          />
          <PrivateRoute exact path={Routes.stores.home} component={Store} />
          <PrivateRoute
            exact
            path={Routes.stores.details}
            component={StoreDetails}
          />
          <PrivateRoute
            exact
            path={Routes.stores.heatmap}
            component={StoreHeatmap}
          />
          <PrivateRoute
            exact
            path={Routes.splashPages.home}
            component={SplashPages}
          />
          <PrivateRoute
            exact
            path={Routes.splashPages.details}
            component={SplashPageDetails}
          />
          <Route
            exact
            path={Routes.deals.shippingConfiguration}
            component={ShippingConfiguration}
          />
          <Route exact path={Routes.deals.shipping} component={Shipping} />
          <PrivateRoute
            exact
            path={Routes.settings.home}
            component={Settings}
          />
          <PrivateRoute
            exact
            path={Routes.mailing.home}
            component={MailingList}
          />

          <PrivateRoute exact path={Routes.media.home} component={MediaList} />
          <PrivateRoute
            exact
            path={Routes.media.newMedia}
            component={CreateAdCampaign}
          />

          <PrivateRoute
            exact
            path={Routes.media.modifyMedia}
            component={UpdateAdCampaign}
          />

          <PrivateRoute
            exact
            path={Routes.media.statistic}
            component={AdDetails}
          />
          <PrivateRoute
            exact
            path={Routes.media.statisticStore}
            component={MediaStatisticsStore}
          />

          <PrivateRoute exact path={Routes.campanias.home} component={CampaniaList} />
          
          <PrivateRoute
            exact
            path={Routes.campanias.newCampaign}
            component={CreateCampaign}
          />
           <PrivateRoute
            exact
            path={Routes.campanias.editCampaign}
            component={UpdateCampaign}
          />
          <PrivateRoute
            exact
            path={Routes.campanias.viewCampaign}
            component={CampaniaDetails}
          />
          <Route exact path={Routes.ap.connections} component={APConnections} />
          
          <Route exact path={Routes.audiences.home} component={AudienceList} />
          <Route exact path={Routes.audiences.newAudience} component={CreateAudience} />
          <Route exact path={Routes.audiences.editAudience} component={EditAudience} />
          <Route exact path={Routes.audiences.details} component={DetailsAudience} />

          <Route
            path="*"
            component={() => <Redirect to={Routes.statistics.home} />}
          />
        </Switch>
      </ToastContextProvider>
    </Principal>
  );
}
