import React, { useRef, useState, useEffect } from "react";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base.js";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base.js";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base.js";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base.js";
import {getStatsCampaignByAudience} from "../../../service/Campaign.service.js";
import { filterRangeNumber, filterRangeNumberComa, filterRangeNumberPorcent  } from "../../../utils/commons.util.js";



export default function CampaignResultsAudienceTable({ campaignId, handleCreateAd  }) {

  const audienceTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [audienceCollection, setAudienceCollection] = useState([]); // Inicializado arreglo de datos de audiencias
  const [selectedRow, setSelectedRow] = useState(null); 

  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, setFilters] = useState({});
  const ROWS_COUNT = 10;
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
  });

 
  // ** Carga inicial
  useEffect(() => {
    let mount = true;

    if (mount) {
      gettingAdDataByCampaign();
    }
    return () => {
      mount = false;
    };
  }, []);

 
  // Función para pbtener la información de las audiencias con sus contactos
  const gettingAdDataByCampaign = async () => {
    setLoading(true);
    const rawMediaAudience = await getStatsCampaignByAudience(campaignId);
    setAudienceCollection(rawMediaAudience.result);
    setLoading(false);
  };


  useEffect(() => {
    setIsFiltered(Object.keys(filters).length > 0);
  }, [filters]);



  //**** Sección de filtros de la tabla

  const nameAudienceFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={audienceTableRef}
        field={"AUDIENCE_NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
        className="custom-place"

      />
    );
  };

  const contactsFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={audienceTableRef}
        field={"CONTACTS_COUNT"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };


  const renderedFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={audienceTableRef}
        field={"RENDERED"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const clicksFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={audienceTableRef}
        field={"CLICKS"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={100}
        matchFilter="custom"
      />
    );
  };

  const CTRFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={audienceTableRef}
        field={"CTR"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={100}
        matchFilter="custom"
      />
    );
  };


 /*** Trabajo con templates para NeoTable */

  const rowClassName = (rowData) => {
    return rowData.ID_MEDIA == selectedRow ? "selected-row" : ""; // Aplica clase si es la fila seleccionada
  };

  
const emptyContent = () => {
  if (isFiltered) {
      return <span>No existen audiencias</span>;
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <p style={{ fontSize:'20px', fontWeight: 'bold', margin: '0' }}>Sin audiencias asignadas</p>
    </div>
  );
};


const onFilter = (event) => {
  let _lazyParams = { ...lazyParams, ...event };
  _lazyParams["first"] = 0;
  setLazyParams(_lazyParams);
  setIsFiltered(Object.keys(event.filters).length > 0);
};


// Agrupación de elementos
function headerTemplate(data) {
  return (
    <>
      <span className="custom-header-container" style={{ display: 'flex', alignItems: 'center', padding: '4px 0' }}>
        <p className="store-row-group" style={{ fontWeight: 'bold', color: '#194893', margin: 0, lineHeight: '1.2' }}>
          Asignaciones actuales
        </p>
      </span>
    </>
  );
}

const footerTemplate = (group) => {
 
};

function getGroupingProps() {
  
  return {
    rowGroupMode: "subheader",
    groupField: "LOCATION",
    rowGroupHeaderTemplate: headerTemplate,
    rowGroupFooterTemplate: () => null,
  };
}

const ctrBodyTemplate = (data) => {
  // Si CTR ya viene como cadena con %, la devuelve; si no, agrega '%' al número
  return typeof data.CTR === "string" ? data.CTR : `${data.CTR}%`;
};



  return (
    <>
   
      <NeoTable
        emptyMessage={emptyContent()}
        ref={audienceTableRef}
        value={audienceCollection}
        loading={loading}
        removableSort
        dataKey="AUDIENCE_ID"
        paginator
        rows={5}
        selectionMode={"single"}
        rowClassName={rowClassName} 
        onFilter={onFilter}
        filters={lazyParams.filters}
        {...getGroupingProps()}
      >
    
        <NeoTableColumn
          field="AUDIENCE_NAME"
          header="Audiencia"
          placeholder="Audiencia"
          sortable
          filter
          filterElement={nameAudienceFilterElement()}
          style={{ width: "20%", textAlign: "left" }}
        />
   
        <NeoTableColumn
          field="CONTACTS_COUNT"
          header="Contactos incluidos"
          sortable
          filter
          filterElement={contactsFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
          style={{ width: "15%" }}
        />

        <NeoTableColumn
          field="RENDERED"
          header="Impresiones"
          sortable
          filter
          filterElement={renderedFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
          style={{ width: "15%" }}
        />

        <NeoTableColumn
          field="CLICKS"
          header="Clics"
          sortable
          filter
          filterElement={clicksFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
          style={{ width: "15%" }}
        />

        <NeoTableColumn
          field="CTR"
          header="CTR"
          sortable
          filter
          filterElement={CTRFilterElement()}
          filterFunction={filterRangeNumberPorcent}
          filterMatchMode="custom"
          body={(rowData) => rowData.CTR || 0}
          style={{ width: "15%" }}
        />
     
      
      </NeoTable>
    </>
  );
}
