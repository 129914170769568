import NeoTooltip_icon from "design/design_components/neo/overlay/NeoTooltip_icon.base";
import { forwardRef, useImperativeHandle, useState } from "react";
import { testAudienceRules } from "service/Audience.service";
import * as AudienceTypes from "../../../models/audience-rule.model"
import useAudienceContacts from "hooks/useAudienceContacts.hook";

/**
 * @typedef {{
 *  rules: AudienceTypes.AudienceRuleModel[]
 *  [key: string]: any,
 * }} AudienceContactCounterProps
 * 
 * @typedef {{
 *  updateContacts: () => Promise<void>
 * }} AudienceContactCounterHandle
*/ 

/**
 * @param {AudienceContactCounterProps} param0 
 * @param {React.Ref<AudienceContactCounterHandle>} ref 
 */
function AudienceContactCounter({ rules, ...props}, ref) {
    const [isLoading, setIsLoading] = useState(false);
    const { contacts, fetch } = useAudienceContacts({ rules, immediate: false });

    useImperativeHandle(ref, () => ({ updateContacts }));

    async function updateContacts() {
        setIsLoading(true);
        
        await fetch(rules);

        setIsLoading(false);
    }

    return (
        <div className="p-d-flex p-flex-column gap-4">
            <div className="p-d-flex p-ai-center fs-14 p-0 m-0">
                Contactos incluidos
                <NeoTooltip_icon position="bottom" extra="inline-tooltip" text="Es el número de contactos que cumplen con los criterios de la audiencia, el cual puede variar según su comportamiento al momento del anuncio." />
            </div>
            <span className="fs-20 bold">
                {isLoading && <i className="pi pi-spin pi-spinner text-black"></i>}
                {!isLoading && contacts.toLocaleString()}
            </span>
        </div>
    )
}

export default forwardRef(AudienceContactCounter);